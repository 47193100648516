import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { GlobalStyle } from './components/GlobalStyle';
import React, { Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, useHistory } from 'react-router';

import { AccessDenied, BaseLayout, Layout, NotFound, RepairProcessLayout } from './components';
import Admin from './containers/Admin/Admin';
import Product from './containers/Admin/components/ProductsTab/Product';
import Dashboard from './containers/Dashboard/Dashboard';
import Home from './containers/Home/Home';
import Login from './containers/Login';
import Preferences from './containers/Preferences/Preferences';
import RecoverEmail from './containers/RecoverEmail';
import RepairDetail from './containers/RepairDetail/RepairDetail';
import AddAnother from './containers/RepairProcess/AddAnother';
import Confirmation from './containers/RepairProcess/Confirmation';
import OrderSummary from './containers/RepairProcess/OrderSummary';
import RepairItems from './containers/RepairProcess/RepairItems';
import Shipping from './containers/RepairProcess/Shipping';
import VerifyEmail from './containers/VerifyEmail/VerifyEmail';
import ServiceOrder from './containers/Admin/components/ServiceOrdersTab/ServiceOrder';
import { Permissions } from './enums';
import { auth0 } from './helpers/auth0';
import useUser from './hooks/useUser';
import { NAVIGATION } from './store/types';
import './styles/app.scss';
import { useFeatureToggles } from './hooks';
import { getSupportCenterRedirectRoute } from './helpers/supportCenterRedirect';

const AppRoute = ({ component: Component, layout: Layout, isProtected, title, ...rest }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  // Trigger navigation event in Redux
  useEffect(() => {
    history.listen(() => {
      dispatch({ type: NAVIGATION });
    });
  }, []);

  const render = props => {
    return (
      <Layout {...rest} {...props}>
        <Component {...props} />
      </Layout>
    );
  };

  document.title = title ? `${title} | Milwaukee Service Dashboard` : `Milwaukee Service Dashboard`;

  return <Route {...rest} render={render} />;
};

const ProtectedRoute = ({ component, ...rest }) => {
  const { isAuthenticated } = useAuth0();
  const { redirectToSupportCenter } = useFeatureToggles();

  if (!isAuthenticated) {
    return <Route component={withAuthenticationRequired(Login)} />;
  }

  if (redirectToSupportCenter) {
    const { path, computedMatch } = rest;
    const redirectRoute = getSupportCenterRedirectRoute(path, computedMatch.params);
    if (redirectRoute) {
      window.location.href = redirectRoute;
    }
  }

  return <AppRoute component={component} {...rest} />;
};

const AdminRoute = ({ permissionRequired, secondaryPermissionRequired, component, ...rest }) => {
  const { permissions, isAdmin } = useUser();
  if (permissionRequired) {
    if (permissions()[permissionRequired] || permissions()[secondaryPermissionRequired]) {
      return <ProtectedRoute component={component} {...rest} />;
    } else {
      return <AppRoute component={AccessDenied} layout={Layout} />;
    }
  } else if (isAdmin) {
    return <ProtectedRoute component={component} {...rest} />;
  } else {
    return <AppRoute component={AccessDenied} layout={Layout} />;
  }
};

// The router runs FIRST matching on the incoming path.
const App = () => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  if (isAuthenticated) {
    auth0.setGetAccessTokenSilently(getAccessTokenSilently);
  }

  return (
    <Fragment>
      <GlobalStyle />
      <Switch>
        <Route exact path='/login' component={Login} />
        <AppRoute exact path='/' component={Home} layout={BaseLayout} />
        <ProtectedRoute exact path='/dashboard' component={Dashboard} layout={Layout} />
        <ProtectedRoute
          exact
          path='/repairprocess/warrantyinformation/:id'
          component={RepairItems}
          layout={RepairProcessLayout}
          title='Warranty Information'
        />
        <ProtectedRoute
          exact
          path='/repairprocess/warrantyinformation'
          component={RepairItems}
          layout={RepairProcessLayout}
          title='Warranty Information'
        />
        <ProtectedRoute
          exact
          path='/repairprocess/productdetail/:id'
          component={RepairItems}
          layout={RepairProcessLayout}
          title='Product Detail'
        />
        <ProtectedRoute
          exact
          path='/repairprocess/productdetail'
          component={RepairItems}
          layout={RepairProcessLayout}
          title='Product Detail'
        />
        <ProtectedRoute
          exact
          path='/repairprocess/addanother'
          component={AddAnother}
          layout={RepairProcessLayout}
          title='Add Another Product'
        />
        <ProtectedRoute
          exact
          path='/repairprocess/shipping'
          component={Shipping}
          layout={RepairProcessLayout}
          title='Shipping'
        />
        <ProtectedRoute
          exact
          path='/repairprocess/confirmation'
          component={Confirmation}
          layout={RepairProcessLayout}
          title='Confirmation'
        />
        <ProtectedRoute
          exact
          path='/repairprocess/ordersummary/:hash'
          component={OrderSummary}
          layout={RepairProcessLayout}
          title='Order Summary'
        />
        <ProtectedRoute exact path='/preferences' component={Preferences} layout={Layout} title='Preferences' />
        <ProtectedRoute path='/repairdetail/:id' component={RepairDetail} layout={Layout} title='Repair Detail' />
        <ProtectedRoute exact path='/recoveremail' component={RecoverEmail} layout={Layout} title='Recover Email' />
        <ProtectedRoute exact path='/verifyemail/:token' component={VerifyEmail} layout={Layout} />
        <AdminRoute exact path='/admin' component={Admin} layout={Layout} title='Admin' />
        <AdminRoute
          exact
          path='/admin/products'
          component={Admin}
          layout={Layout}
          title='Products Admin'
          permissionRequired={Permissions.PRODUCTS}
        />
        <AdminRoute
          exact
          path='/admin/products/:sku'
          component={Product}
          layout={Layout}
          title='Product Admin'
          permissionRequired={Permissions.PRODUCTS}
        />
        <AdminRoute
          exact
          path='/admin/serviceorders'
          component={Admin}
          layout={Layout}
          title='Service Orders Admin'
          permissionRequired={Permissions.SERVICEORDERS}
        />
        <AdminRoute
          exact
          path='/admin/serviceCenters'
          component={Admin}
          layout={Layout}
          title='Service Centers Admin'
          permissionRequired={Permissions.SERVICECENTERS}
        />
        <AdminRoute
          exact
          path='/admin/productProblems'
          component={Admin}
          layout={Layout}
          title='Product Problem Admin'
          permissionRequired={Permissions.PRODUCTPROBLEMS}
        />
        <AdminRoute
          exact
          path='/admin/productIncluded'
          component={Admin}
          layout={Layout}
          title='Product Included Admin'
          permissionRequired={Permissions.PRODUCTINCLUSIONS}
        />
        <AdminRoute
          exact
          path='/admin/serviceorders/:id'
          component={ServiceOrder}
          layout={Layout}
          title='Service Order Admin'
          permissionRequired={Permissions.SERVICEORDERS}
        />
        <AdminRoute exact path='/admin/routingRules' component={Admin} layout={Layout} title='Routing Rules Admin' />
        <AdminRoute
          exact
          path='/admin/announcements'
          component={Admin}
          layout={Layout}
          title='Announcements'
          permissionRequired={Permissions.ANNOUNCEMENTS}
        />
        <AdminRoute
          exact
          path='/admin/reports'
          component={Admin}
          layout={Layout}
          title='Reports'
          permissionRequired={Permissions.SERVICECENTERS}
          secondaryPermissionRequired={Permissions.SERVICEORDERS}
        />
        <AppRoute component={NotFound} layout={Layout} title={'Page Not Found'} />
      </Switch>
    </Fragment>
  );
};

export default App;
