import jwtDecode from 'jwt-decode';

const clearIgnoreKeys = ['feature:redirect-to-sc-enabled'];

export const clearSession = () => {
  Object.keys(localStorage).forEach(key => {
    if (clearIgnoreKeys.some(ignoreKey => key.startsWith(ignoreKey))) return;
    localStorage.removeItem(key);
  });
};

export const setUserHasOrders = value => {
  localStorage.setItem('servicedashboard:userhasorders', JSON.stringify(value));
};

export const userHasOrders = () => {
  return JSON.parse(localStorage.getItem('servicedashboard:userhasorders') || false);
};

export const setUserMetadata = value => {
  localStorage.setItem('servicedashboard:userMetadata', JSON.stringify(value));
};

export const getUserId = () => {
  return localStorage.getItem('servicedashboard:userId');
};

export const setUserId = value => {
  localStorage.setItem('servicedashboard:userId', value);
};

export const getUserMetadata = () => {
  return JSON.parse(localStorage.getItem('servicedashboard:userMetadata') || '{}');
};

export const setShowWhatsNew = value => {
  localStorage.setItem('servicedashboard:showwhatsnew', JSON.stringify(value));
};

export const getShowWhatsNew = () => {
  return JSON.parse(localStorage.getItem('servicedashboard:showwhatsnew') || false);
};

export const setAiInstrumentationKey = value => {
  localStorage.setItem('servicedashboard:aiinstrumentationkey', value);
};

export const getAiInstrumentationKey = () => {
  return localStorage.getItem('servicedashboard:aiinstrumentationkey') || '';
};

export const updateMetSession = metSession => {
  return new Promise(resolve => {
    localStorage.setItem('servicedashboard:metsession', JSON.stringify(metSession));

    resolve();
  });
};

export const getMetSession = () => {
  return JSON.parse(localStorage.getItem('servicedashboard:metsession') || '{}');
};

export const hasMetSession = () => {
  let decodedMetSession = decodeMetSession();

  return decodedMetSession !== undefined && decodedMetSession !== null;
};

export const decodeMetSession = () => {
  let metSession = getMetSession();

  if (metSession && JSON.stringify(metSession) !== '{}') {
    let decodedToken;

    try {
      decodedToken = jwtDecode(metSession.token);
    } catch (e) {
      // Unable to decode JWT
    }

    return decodedToken;
  }

  return null;
};

export const setMetSessionExpiry = expiry => {
  return new Promise(resolve => {
    localStorage.setItem('servicedashboard:metsession-expiry', expiry);

    resolve();
  });
};

export const getMetSessionExpiry = () => {
  return localStorage.getItem('servicedashboard:metsession-expiry') || '';
};

export const setLocale = locale => {
  return localStorage.setItem('servicedashboard:locale', locale);
};

export const getLocale = () => {
  return localStorage.getItem('servicedashboard:locale');
};

export const setHideMissingAccountNumberNotification = isHidden => {
  localStorage.setItem('servicepreferences:hidemissingaccountnumbernotification', isHidden);
};

export const getHideMissingAccountNumberNotification = () => {
  return localStorage.getItem('servicepreferences:hidemissingaccountnumbernotification');
};

export const getRedirectToSupportCenterEnabled = (userId) => {
  const key = `feature:redirect-to-sc-enabled:${userId}`;
  const redirectToSupportCenterEnabled = localStorage.getItem(key);
  return redirectToSupportCenterEnabled === 'true';
}

export const setRedirectToSupportCenterEnabled = (enabled, userId) => {
  const key = `feature:redirect-to-sc-enabled:${userId}`;
  localStorage.setItem(key, enabled);
}

export const hasRedirectToSupportCenterEnabled = () => {
  var storageKeys = Object.keys(localStorage);
  return storageKeys.some(key => key.startsWith('feature:redirect-to-sc-enabled'));
}